import * as React from "react";
import { ChevronRight, Close, Delete, ExpandMore, Info, Launch, Save } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Divider, Grid2, InputAdornment, IconButton, MenuItem, Radio, Table, TableHead, TableBody, TableRow, TableCell, TextField, Tooltip, Typography, Skeleton, Alert, Switch, } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";
import CoreChoiceField from "@app/common/CoreChoiceField";
import CoreError from "@app/common/CoreError";
import CoreLoading from "@app/common/CoreLoading";
import CoreNumberField from "@app/common/CoreNumberField";
import RouterLink from "@app/common/RouterLink";
import useErrorDialog from "@app/hooks/useErrorDialog";
import { useListChoiceSets } from "@app/orval/api/choice-sets";
import { useListEntityTypes } from "@app/orval/api/entity-types";
import { useAddField, useDeleteField, useGetField, useListFields, useUpdateField, } from "@app/orval/api/field-definitions";
import { useListFieldSections } from "@app/orval/api/field-sections";
import { useListFieldTypes } from "@app/orval/api/field-types";
import { useListStages } from "@app/orval/api/stages";
import { useListRoles } from "@app/orval/api/user-roles";
import { INFINITE_CACHE_PARAMS, REFETCH_CACHE_PARAMS } from "@app/orval/config";
import { CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS } from "@app/util/AppConfig";
import Session from "@app/util/Session";
import { replaceItemInArray } from "@app/util/Utils";
import FormulaField from "./components/FormulaField";
import { getAutocompleteSuggestionsForField } from "../sections/utils";
const DEFAULT_SUBMISSION = {
    name: "",
    entity_type: "MAtrix",
    field_type: "text",
    description: "",
    help_text: "",
    section_uid: null,
    permissions: [],
    warn_validation_formula: null,
    reject_validation_formula: null,
    visible_formula: null,
    editable_formula: null,
    transform_formula: null,
};
const FieldForm = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17;
    const { backLink, uid } = props;
    const errorDialog = useErrorDialog();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState(uid ? null : DEFAULT_SUBMISSION);
    const queryClient = useQueryClient();
    const listRolesApi = useListRoles(INFINITE_CACHE_PARAMS);
    const userRoles = (_b = (_a = listRolesApi.data) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : null;
    const listSectionsApi = useListFieldSections({}, REFETCH_CACHE_PARAMS);
    const sections = (_d = (_c = listSectionsApi.data) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : null;
    const listStagesApi = useListStages({}, REFETCH_CACHE_PARAMS);
    const stages = (_f = (_e = listStagesApi.data) === null || _e === void 0 ? void 0 : _e.data) !== null && _f !== void 0 ? _f : null;
    const listChoiceSetsApi = useListChoiceSets({}, REFETCH_CACHE_PARAMS);
    const choiceSets = (_h = (_g = listChoiceSetsApi.data) === null || _g === void 0 ? void 0 : _g.data) !== null && _h !== void 0 ? _h : null;
    const listFieldTypesApi = useListFieldTypes(INFINITE_CACHE_PARAMS);
    const fieldTypes = (_k = (_j = listFieldTypesApi.data) === null || _j === void 0 ? void 0 : _j.data) !== null && _k !== void 0 ? _k : null;
    const listEntityTypesApi = useListEntityTypes(INFINITE_CACHE_PARAMS);
    const entityTypes = (_m = (_l = listEntityTypesApi.data) === null || _l === void 0 ? void 0 : _l.data) !== null && _m !== void 0 ? _m : null;
    const listFieldsApi = useListFields({}, INFINITE_CACHE_PARAMS);
    const fields = (_p = (_o = listFieldsApi.data) === null || _o === void 0 ? void 0 : _o.data) !== null && _p !== void 0 ? _p : null;
    const getFieldApi = useGetField(uid !== null && uid !== void 0 ? uid : "", { query: { enabled: false } });
    const field = (_r = (_q = getFieldApi.data) === null || _q === void 0 ? void 0 : _q.data) !== null && _r !== void 0 ? _r : null;
    const createFieldApi = useAddField();
    const updateFieldApi = useUpdateField();
    const deleteFieldApi = useDeleteField();
    const error = (_x = (_w = (_v = (_u = (_t = (_s = listRolesApi.error) !== null && _s !== void 0 ? _s : listSectionsApi.error) !== null && _t !== void 0 ? _t : listChoiceSetsApi.error) !== null && _u !== void 0 ? _u : listFieldTypesApi.error) !== null && _v !== void 0 ? _v : listEntityTypesApi.error) !== null && _w !== void 0 ? _w : listFieldsApi.error) !== null && _x !== void 0 ? _x : getFieldApi.error;
    const isLoading = createFieldApi.isPending || updateFieldApi.isPending || deleteFieldApi.isPending || getFieldApi.isFetching;
    React.useEffect(() => {
        if (uid) {
            getFieldApi
                .refetch()
                .then((result) => {
                var _a, _b;
                const { data } = result.data;
                setFormData(Object.assign(Object.assign({}, data), { section_uid: (_b = (_a = data.section) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : null, choice_set_uid: data.choice_set ? data.choice_set.uid : undefined }));
            })
                .catch(errorDialog);
        }
        else {
            setFormData(DEFAULT_SUBMISSION);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uid]);
    // if entity type has changed, reset the include_in_followupboss field
    React.useEffect(() => {
        if (!uid) {
            if ((formData === null || formData === void 0 ? void 0 : formData.entity_type) === "Buyer Lead" || (formData === null || formData === void 0 ? void 0 : formData.entity_type) === "Seller Lead") {
                setFormData((prev) => (Object.assign(Object.assign({}, prev), { include_in_followupboss: false })));
            }
            else {
                setFormData((prev) => (Object.assign(Object.assign({}, prev), { include_in_followupboss: undefined })));
            }
        }
    }, [formData === null || formData === void 0 ? void 0 : formData.entity_type, uid]);
    const isValid = React.useMemo(() => {
        var _a, _b;
        return !!((formData === null || formData === void 0 ? void 0 : formData.name) &&
            (!["number", "date", "datetime", "time"].includes(formData.field_type) || ((_a = formData.format) === null || _a === void 0 ? void 0 : _a.trim())) &&
            (!["dropdown"].includes(formData.field_type) || formData.choice_set_uid) &&
            (!["formula"].includes(formData.field_type) || ((_b = formData.formula) === null || _b === void 0 ? void 0 : _b.trim())));
    }, [formData]);
    const saveField = React.useCallback(() => {
        confirm({
            title: "Are you sure?",
            description: "Certain features in the application are dependent on fields, their names, and other metadata. Changing fields will almost certainly break some features of the application if not done carefully; best to consult a developer before making adjustments here.",
            confirmationText: "Save",
        }).then((result) => {
            var _a, _b, _c, _d, _e, _f;
            if (result.confirmed) {
                const data = formData;
                const params = Object.assign(Object.assign({}, omit(data, "dependents", "dependencies", "uid", "section", "choice_set")), { name: data.name, field_type: data.field_type, entity_type: data.entity_type, description: data.description, help_text: data.help_text, include_in_followupboss: data.include_in_followupboss, section_uid: data.section_uid, min: data.field_type === "number" ? data.min : undefined, max: data.field_type === "number" ? data.max : undefined, format: ["formula", "date", "datetime", "time", "number"].includes(data.field_type)
                        ? data.format
                        : undefined, formula: data.field_type === "formula" ? data.formula : undefined, choice_set_uid: data.field_type === "choice" ? data.choice_set_uid : undefined, allows_multiple: data.field_type === "choice" ? ((_a = data.allows_multiple) !== null && _a !== void 0 ? _a : false) : undefined, is_nullable: data.field_type === "checkbox" ? false : undefined, transform_formula: data.field_type === "text" ? ((_b = data.transform_formula) === null || _b === void 0 ? void 0 : _b.trim()) || null : null, warn_validation_formula: data.field_type !== "formula" ? ((_c = data.warn_validation_formula) === null || _c === void 0 ? void 0 : _c.trim()) || null : null, reject_validation_formula: data.field_type !== "formula" ? ((_d = data.reject_validation_formula) === null || _d === void 0 ? void 0 : _d.trim()) || null : null, editable_formula: data.field_type !== "formula" ? ((_e = data.editable_formula) === null || _e === void 0 ? void 0 : _e.trim()) || null : null, visible_formula: data.field_type !== "formula" ? ((_f = data.visible_formula) === null || _f === void 0 ? void 0 : _f.trim()) || null : null, permissions: data.permissions });
                if (uid) {
                    updateFieldApi
                        .mutateAsync({ uid, data: omit(params, "entity_type", "field_type", "section_uid") })
                        .then((result) => {
                        queryClient.setQueryData(listFieldsApi.queryKey, (old) => {
                            if (!old)
                                return old;
                            return Object.assign(Object.assign({}, old), { data: replaceItemInArray({ arr: old.data, val: result.data, where: (item) => item.uid === uid }) });
                        });
                        navigate(backLink !== null && backLink !== void 0 ? backLink : "/field-management");
                    })
                        .catch(errorDialog);
                }
                else {
                    createFieldApi
                        .mutateAsync({ data: params })
                        .then((result) => {
                        queryClient.setQueryData(listFieldsApi.queryKey, (old) => {
                            if (!old)
                                return old;
                            return Object.assign(Object.assign({}, old), { data: [...old.data, result.data] });
                        });
                        navigate(backLink !== null && backLink !== void 0 ? backLink : "/field-management");
                    })
                        .catch(errorDialog);
                }
            }
        });
    }, [
        confirm,
        createFieldApi,
        errorDialog,
        formData,
        listFieldsApi.queryKey,
        navigate,
        queryClient,
        uid,
        updateFieldApi,
        backLink,
    ]);
    const deleteField = React.useCallback(() => {
        confirm(Object.assign(Object.assign({}, CONFIRMATION_DIALOG_DELETE_ITEM_DEFAULT_OPTIONS), { title: "Are you sure?", description: "Removing a field may break any part of the application in which the field is used; best to consult a developer before making changes here.", confirmationText: "Confirm" })).then((result) => {
            if (result.confirmed) {
                deleteFieldApi
                    .mutateAsync({ uid: uid })
                    .then(() => {
                    queryClient.setQueryData(listFieldsApi.queryKey, (old) => {
                        if (!old)
                            return old;
                        return Object.assign(Object.assign({}, old), { data: old.data.filter((x) => x.uid !== uid) });
                    });
                    navigate(backLink !== null && backLink !== void 0 ? backLink : "/field-management");
                })
                    .catch(errorDialog);
            }
        });
    }, [deleteFieldApi, confirm, uid, errorDialog, queryClient, listFieldsApi.queryKey, navigate, backLink]);
    const setName = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { name: e.target.value })));
    }, [setFormData]);
    const setEntityType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { entity_type: e.target.value })));
    }, [setFormData]);
    const setFieldType = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { field_type: e.target.value })));
    }, [setFormData]);
    const setDescription = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { description: e.target.value })));
    }, [setFormData]);
    const setHelpText = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { help_text: e.target.value })));
    }, [setFormData]);
    const setIncludeInFollowUpBoss = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { include_in_followupboss: checked })));
    }, [setFormData]);
    const setSection = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { section_uid: e.target.value || null })));
    }, [setFormData]);
    const setFormat = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { format: e.target.value })));
    }, [setFormData]);
    const setMin = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { min: value })));
    }, [setFormData]);
    const setMax = React.useCallback((value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { max: value })));
    }, [setFormData]);
    const setChoiceSet = React.useCallback((e) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { choice_set_uid: e.target.value || undefined })));
    }, [setFormData]);
    const setAllowsMultiple = React.useCallback((_, checked) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { allows_multiple: checked })));
    }, [setFormData]);
    const setFormulaText = React.useCallback((text) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { formula: text })));
    }, [setFormData]);
    const setWarnValidationText = React.useCallback((text) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { warn_validation_formula: text })));
    }, [setFormData]);
    const setRejectValidationText = React.useCallback((text) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { reject_validation_formula: text })));
    }, [setFormData]);
    const setVisibleFormulaText = React.useCallback((text) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { visible_formula: text })));
    }, [setFormData]);
    const setEditableFormulaText = React.useCallback((text) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { editable_formula: text })));
    }, [setFormData]);
    const setTransformFormulaText = React.useCallback((text) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { transform_formula: text })));
    }, [setFormData]);
    const setPermissionLevel = React.useCallback((role) => (e, checked) => {
        setFormData((prev) => {
            const permissions = prev.permissions.filter((p) => p.role !== role);
            if (checked) {
                if (e.target.value === "edit") {
                    permissions.push({
                        role,
                        can_edit: true,
                    });
                }
                else if (e.target.value === "view") {
                    permissions.push({
                        role,
                        can_edit: false,
                    });
                }
            }
            return Object.assign(Object.assign({}, prev), { permissions });
        });
    }, [setFormData]);
    const renderTree = React.useCallback((fields_, param) => {
        return fields_
            .filter((f) => f)
            .map((f) => (React.createElement(TreeItem, { key: f.name, itemId: f.name, label: f.description ? (React.createElement(Tooltip, { title: f.description },
                React.createElement(RouterLink, { to: backLink !== null && backLink !== void 0 ? backLink : `/field-management?field=${f.uid}` }, f.name))) : (React.createElement(RouterLink, { to: backLink !== null && backLink !== void 0 ? backLink : `/field-management?field=${f.uid}` }, f.name)) }, Array.isArray(f[param]) && f[param].length > 0
            ? renderTree(f[param].map((fieldUid) => fields[fieldUid]), param)
            : null)));
    }, [backLink, fields]);
    const autocompleteSuggestions = React.useMemo(() => {
        return fields && (formData === null || formData === void 0 ? void 0 : formData.entity_type)
            ? getAutocompleteSuggestionsForField(formData.entity_type, fields, stages !== null && stages !== void 0 ? stages : undefined)
            : null;
    }, [fields, formData === null || formData === void 0 ? void 0 : formData.entity_type, stages]);
    const autocompleteSuggestionsForEntityType = React.useMemo(() => {
        return [];
    }, []);
    const autocompleteSuggestionsWithValueAndUser = React.useMemo(() => {
        return autocompleteSuggestions
            ? [
                { label: "$VALUE", type: "any", documentation: "The value of this field" },
                {
                    label: "$USER",
                    type: "Map",
                    documentation: "The user doing the update. Use `$USER.roles` to validate against the array of user roles.",
                },
                ...autocompleteSuggestionsForEntityType,
                ...autocompleteSuggestions,
            ]
            : null;
    }, [autocompleteSuggestions, autocompleteSuggestionsForEntityType]);
    if (error) {
        return React.createElement(CoreError, { error: error });
    }
    if (isLoading || !formData) {
        return React.createElement(CoreLoading, null);
    }
    return (React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap", spacing: 2 },
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pb: 0 },
                React.createElement(Typography, { variant: "h6" }, formData.name.trim() || (uid ? "Edit Field" : "New Field")))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2 },
                React.createElement(Grid2, { container: true, spacing: 3 },
                    React.createElement(Grid2, { size: { xs: 12, md: 6, lg: 4 } },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, required: true, label: "Name", value: formData.name, onChange: setName })),
                    React.createElement(Grid2, { size: { xs: 12, md: 3, lg: 4 } }, entityTypes ? (React.createElement(CoreChoiceField, { size: "small", fullWidth: true, required: true, disabled: !!uid, label: "Entity Type", value: formData.entity_type, onChange: setEntityType, select: true }, entityTypes.map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))) : (React.createElement(Skeleton, null))),
                    React.createElement(Grid2, { size: { xs: 12, md: 3, lg: 4 } }, fieldTypes ? (React.createElement(TextField, { size: "small", variant: "outlined", fullWidth: true, required: true, disabled: !!uid || !fieldTypes, label: "Field Type", value: formData.field_type, onChange: setFieldType, select: true }, (fieldTypes !== null && fieldTypes !== void 0 ? fieldTypes : []).map((obj) => (React.createElement(MenuItem, { key: obj.name, value: obj.name }, obj.name))))) : (React.createElement(Skeleton, null))),
                    React.createElement(Grid2, { size: { xs: 12, sm: 12 } },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, multiline: true, minRows: 1, maxRows: 8, label: "Description", helperText: "Internal documentation for the field. This is not visible to users.", value: (_y = formData.description) !== null && _y !== void 0 ? _y : "", onChange: setDescription })),
                    React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Help Text", helperText: "Help text that can be displayed to the user in certain views.", value: (_z = formData.help_text) !== null && _z !== void 0 ? _z : "", onChange: setHelpText })),
                    (formData.entity_type === "Buyer Lead" || formData.entity_type === "Seller Lead") && (React.createElement(Grid2, { size: 12 },
                        React.createElement(Grid2, { container: true, direction: "column", wrap: "nowrap" },
                            React.createElement(Grid2, null,
                                React.createElement(FormControlLabel, { label: "Include In Follow Up Boss", control: React.createElement(Switch, { color: "primary", size: "small", onChange: setIncludeInFollowUpBoss, checked: (_0 = formData.include_in_followupboss) !== null && _0 !== void 0 ? _0 : false }) })),
                            React.createElement(Grid2, null,
                                React.createElement(Typography, { variant: "caption" }, "Only relevant for Buyer Leads and Seller Leads. Set this to OFF if the field is already native to Follow Up Boss."))))),
                    React.createElement(Grid2, { size: 12 }, sections ? (React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, label: "Section", select: true, value: (_1 = formData.section_uid) !== null && _1 !== void 0 ? _1 : "", onChange: setSection, helperText: "Use this setting to organize fields into sections. This may not be implemented in all views, and generally has no effect on automation.", SelectProps: {
                            displayEmpty: true,
                        }, InputLabelProps: {
                            shrink: true,
                        }, disabled: !!uid },
                        React.createElement(MenuItem, { value: "" }, "No section"),
                        sections
                            .filter((section) => section.entity_type === formData.entity_type)
                            .map((section) => (React.createElement(MenuItem, { key: section.uid, value: section.uid }, section.name))))) : (React.createElement(Skeleton, null)))),
                React.createElement(Divider, { style: { marginTop: "3rem", marginBottom: "1rem" } }),
                React.createElement(Grid2, { container: true, spacing: 3 },
                    ["number", "date", "datetime", "time", "formula"].includes(formData.field_type) && (React.createElement(Grid2, { size: 12 },
                        React.createElement(TextField, { variant: "standard", size: "small", fullWidth: true, required: formData.field_type !== "formula", label: "Format", value: formData.format !== undefined ? formData.format : "", onChange: setFormat }))),
                    formData.field_type === "number" && (React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                        React.createElement(CoreNumberField, { fullWidth: true, label: "Min", size: "small", format: (_2 = formData.format) !== null && _2 !== void 0 ? _2 : "0", number: (_3 = formData.min) !== null && _3 !== void 0 ? _3 : null, onNumberChange: setMin }))),
                    formData.field_type === "number" && (React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                        React.createElement(CoreNumberField, { fullWidth: true, label: "Max", size: "small", format: (_4 = formData.format) !== null && _4 !== void 0 ? _4 : "0", number: (_5 = formData.max) !== null && _5 !== void 0 ? _5 : null, onNumberChange: setMax }))),
                    formData.field_type === "choice" &&
                        (choiceSets ? (React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                            React.createElement(TextField, { fullWidth: true, required: true, label: "Choice Set", size: "small", value: (_6 = formData.choice_set_uid) !== null && _6 !== void 0 ? _6 : null, onChange: setChoiceSet, slotProps: {
                                    input: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement(IconButton, { size: "small", component: RouterLink, to: (() => {
                                                    if (!formData.choice_set_uid) {
                                                        return null;
                                                    }
                                                    const choiceSet = (choiceSets !== null && choiceSets !== void 0 ? choiceSets : []).find((obj) => obj.uid === formData.choice_set_uid);
                                                    return choiceSet ? `/choice-sets/${choiceSet.uid}` : "/choice_sets";
                                                })(), disabled: !formData.choice_set_uid, tabIndex: -1 },
                                                React.createElement(Launch, null)))),
                                    },
                                }, select: true, helperText: (() => {
                                    if (!formData.choice_set_uid) {
                                        return null;
                                    }
                                    const choiceSet = (choiceSets !== null && choiceSets !== void 0 ? choiceSets : []).find((obj) => obj.uid === formData.choice_set_uid);
                                    return choiceSet === null || choiceSet === void 0 ? void 0 : choiceSet.choices.map((obj) => obj.name).join(", ");
                                })() },
                                React.createElement(MenuItem, { key: -1, value: "" }),
                                (choiceSets !== null && choiceSets !== void 0 ? choiceSets : [])
                                    .filter((obj) => !obj.archived || obj.uid === formData.choice_set_uid)
                                    .sort((a, b) => {
                                    if (a.name < b.name)
                                        return -1;
                                    if (a.name > b.name)
                                        return 1;
                                    return 0;
                                })
                                    .map((obj) => (React.createElement(MenuItem, { key: obj.uid, value: obj.uid }, obj.name)))))) : (React.createElement(Skeleton, null))),
                    formData.field_type === "choice" && (React.createElement(Grid2, { size: { xs: 12, sm: 6 } },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { color: "primary", checked: (_7 = formData.allows_multiple) !== null && _7 !== void 0 ? _7 : false, onChange: setAllowsMultiple }), label: "Allows Multiple" }))),
                    formData.field_type === "formula" &&
                        (autocompleteSuggestionsWithValueAndUser ? (React.createElement(Grid2, { size: 12 },
                            React.createElement(Typography, { variant: "body2" }, "Formula"),
                            React.createElement(FormulaField, { formula: (_8 = formData.formula) !== null && _8 !== void 0 ? _8 : "", onValueChange: setFormulaText, autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser }))) : (React.createElement(Skeleton, null))),
                    formData.field_type === "text" &&
                        (autocompleteSuggestionsWithValueAndUser ? (React.createElement(Grid2, { size: 12 },
                            React.createElement(Typography, { variant: "body2" }, "Transform"),
                            React.createElement(FormulaField, { formula: (_9 = formData.transform_formula) !== null && _9 !== void 0 ? _9 : "", onValueChange: setTransformFormulaText, helpText: "A transformation applied to the field value. Use `$VALUE` to refer to the field value, `$<field_name>` to refer to other field values, or `$<USER>` to refer to the current user.", autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser }))) : (React.createElement(Skeleton, null))),
                    formData.field_type !== "formula" &&
                        (autocompleteSuggestionsWithValueAndUser ? (React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                            React.createElement(Typography, { variant: "body2" }, "Warn Validation"),
                            React.createElement(FormulaField, { formula: (_10 = formData.warn_validation_formula) !== null && _10 !== void 0 ? _10 : "", onValueChange: setWarnValidationText, helpText: "Presented as a warning with the given message if the formula evaluates to a string. Use `$VALUE` to refer to the field value or `$<field_name>` to refer to other field values, or `$<USER>` to refer to the current user.", autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser }))) : (React.createElement(Skeleton, null))),
                    formData.field_type !== "formula" &&
                        (autocompleteSuggestionsWithValueAndUser ? (React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                            React.createElement(Typography, { variant: "body2" }, "Reject Validation"),
                            React.createElement(FormulaField, { formula: (_11 = formData.reject_validation_formula) !== null && _11 !== void 0 ? _11 : "", onValueChange: setRejectValidationText, helpText: "Rejects the value with the given error message if the formula evaluates to a string. Use `$VALUE` to refer to the field value, or `$<field_name>` to refer to other field values, or `$USER` to refer to the current user.", autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser }))) : (React.createElement(Skeleton, null))),
                    formData.field_type !== "formula" &&
                        (autocompleteSuggestionsWithValueAndUser ? (React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                            React.createElement(Typography, { variant: "body2" }, "Visible Validation"),
                            React.createElement(FormulaField, { formula: (_12 = formData.visible_formula) !== null && _12 !== void 0 ? _12 : "", onValueChange: setVisibleFormulaText, helpText: "The user will not be able to see the field if the formula evaluates to `false` (although it will still be incorporated into the calculations of any relevant formulas). Assumed to be `true` if the formula evaluates to `undefined`. Use `$CURRENT_VALUE` to refer to the field value, `$NEW_VALUE` to refer to the new field value, `$<field_name>` to refer to other field values, or `$USER` to refer to the current user.", autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser }))) : (React.createElement(Skeleton, null))),
                    formData.field_type !== "formula" &&
                        (autocompleteSuggestionsWithValueAndUser ? (React.createElement(Grid2, { size: { xs: 12, md: 6 } },
                            React.createElement(Typography, { variant: "body2" }, "Editable Validation"),
                            React.createElement(FormulaField, { formula: (_13 = formData.editable_formula) !== null && _13 !== void 0 ? _13 : "", onValueChange: setEditableFormulaText, helpText: "The user will not be able to edit the field if the formula evaluates to `false`. Assumed to be `true` if the formula evaluates to `undefined`. Use `$VALUE` to refer to the field value, `$<field_name>` to refer to other field values, or `$USER` to refer to the current user.", autocompleteSuggestions: autocompleteSuggestionsWithValueAndUser }))) : (React.createElement(Skeleton, null))),
                    userRoles && (React.createElement(Grid2, { container: true, direction: "column", spacing: 1, size: 12, pt: 3 },
                        React.createElement(Grid2, null,
                            React.createElement(Typography, null, "Permissions")),
                        (((_14 = formData.visible_formula) === null || _14 === void 0 ? void 0 : _14.trim()) || ((_15 = formData.editable_formula) === null || _15 === void 0 ? void 0 : _15.trim())) && (React.createElement(Grid2, null,
                            React.createElement(Alert, { severity: "info", variant: "outlined" },
                                React.createElement(Typography, null, "It looks like formula validation is specified for this field. This validation may override the settings input below. If you want a user to be able to view and/or edit a field, make sure the permissions below are enabled AND the formula evaluates to `true` (or anything non-falsey).")))),
                        React.createElement(Grid2, null,
                            React.createElement(Table, { size: "small" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null,
                                        React.createElement(TableCell, null),
                                        React.createElement(TableCell, null, "None"),
                                        React.createElement(TableCell, null, "View"),
                                        React.createElement(TableCell, null, "Edit"))),
                                React.createElement(TableBody, null, userRoles.map((role) => (React.createElement(TableRow, { key: role.name },
                                    React.createElement(TableCell, null, role.name),
                                    ["none", "view", "edit"]
                                        .filter((o) => o)
                                        .map((permissionLevel) => (React.createElement(TableCell, { key: permissionLevel },
                                        React.createElement(Radio, { name: role.name, value: permissionLevel, checked: (() => {
                                                const permission = formData.permissions.length > 0
                                                    ? formData.permissions.find((p) => p.role === role.name)
                                                    : { can_edit: true };
                                                if (permissionLevel === "none") {
                                                    return !permission;
                                                }
                                                if (permissionLevel === "view") {
                                                    return !!(permission && !permission.can_edit);
                                                }
                                                if (permissionLevel === "edit") {
                                                    return !!(permission === null || permission === void 0 ? void 0 : permission.can_edit);
                                                }
                                                return false;
                                            })(), onChange: setPermissionLevel(role.name), color: "primary" }))))))))))))),
                uid && fields && field && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { style: {
                            marginTop: "3rem",
                            marginBottom: "1rem",
                        } }),
                    React.createElement(Grid2, { container: true, size: 12, spacing: 4 },
                        React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                            React.createElement(Grid2, { container: true, size: 12, spacing: 1 },
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, null, "Current Dependents")),
                                React.createElement(Grid2, { style: {
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    } },
                                    React.createElement(Tooltip, { title: `Updating the definition for ${formData.name} may have an effect on these fields.` },
                                        React.createElement(Info, { fontSize: "small" })))),
                            React.createElement(Grid2, { size: 12 }, field.dependents.length > 0 ? (React.createElement(SimpleTreeView, { defaultExpandedItems: ["root"], slots: { collapseIcon: ExpandMore, expandIcon: ChevronRight } }, renderTree(field.dependents.map((fieldUid) => fields[fieldUid]), "dependents"))) : (React.createElement(Grid2, { container: true, spacing: 1 },
                                React.createElement(Typography, { variant: "body2", dangerouslySetInnerHTML: {
                                        __html: `No other fields are dependent on <b>${formData.name.trim() || "this field"}</b>.`,
                                    } }))))),
                        formData.field_type === "formula" && (React.createElement(Grid2, { container: true, size: 12, spacing: 2 },
                            React.createElement(Grid2, { container: true, spacing: 1 },
                                React.createElement(Grid2, null,
                                    React.createElement(Typography, null, "Current Dependencies")),
                                React.createElement(Grid2, { style: {
                                        marginTop: "auto",
                                        marginBottom: "auto",
                                    } },
                                    React.createElement(Tooltip, { title: `Updating any of these fields may change the behavior of ${formData.name}.` },
                                        React.createElement(Info, { fontSize: "small" })))),
                            React.createElement(Grid2, { size: 12 }, field.dependencies.length > 0 ? (React.createElement(SimpleTreeView, { defaultExpandedItems: ["root"], slots: { collapseIcon: ExpandMore, expandIcon: ChevronRight } }, renderTree(field.dependencies.map((fieldUid) => fields[fieldUid]), "dependencies"))) : (React.createElement(Typography, { variant: "body2", dangerouslySetInnerHTML: {
                                    __html: `<b>${formData.name.trim() || "This field"}</b> is not dependent on any other fields.`,
                                } })))))))))),
        React.createElement(Grid2, null,
            React.createElement(Divider, null)),
        React.createElement(Grid2, null,
            React.createElement(Box, { p: 2, pt: 0 },
                React.createElement(Grid2, { container: true, spacing: 2, alignItems: "center" },
                    React.createElement(Grid2, null, uid && (React.createElement(Button, { size: "small", onClick: deleteField, disabled: !((_16 = Session.viewingAsUser) === null || _16 === void 0 ? void 0 : _16.hasRole(["Superadmin"])), tabIndex: -1, startIcon: React.createElement(Delete, null), color: "secondary", variant: "contained" }, "Delete"))),
                    React.createElement(Grid2, { style: { flex: 1 } }),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { component: RouterLink, to: backLink !== null && backLink !== void 0 ? backLink : "/field-management", startIcon: React.createElement(Close, null), tabIndex: -1 }, "Cancel")),
                    React.createElement(Grid2, null,
                        React.createElement(Button, { size: "small", onClick: saveField, disabled: !isValid || !((_17 = Session.viewingAsUser) === null || _17 === void 0 ? void 0 : _17.hasRole(["Superadmin"])), tabIndex: -1, startIcon: React.createElement(Save, null), variant: "outlined" }, "Save")))))));
};
export default FieldForm;
