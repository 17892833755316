/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Get the current user
 */
export const getCurrentUser = (options) => {
    return axios.get(`/api/get_current_user`, options);
};
export const getGetCurrentUserQueryKey = () => {
    return [`/api/get_current_user`];
};
export const getGetCurrentUserQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetCurrentUserQueryKey();
    const queryFn = ({ signal }) => getCurrentUser(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the current user
 */
export function useGetCurrentUser(options) {
    const queryOptions = getGetCurrentUserQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get the current user's Twilio number
 */
export const getMyTwilioNumber = (options) => {
    return axios.get(`/api/get_my_twilio_number`, options);
};
export const getGetMyTwilioNumberQueryKey = () => {
    return [`/api/get_my_twilio_number`];
};
export const getGetMyTwilioNumberQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetMyTwilioNumberQueryKey();
    const queryFn = ({ signal }) => getMyTwilioNumber(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Get the current user's Twilio number
 */
export function useGetMyTwilioNumber(options) {
    const queryOptions = getGetMyTwilioNumberQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary List users with nonexistant Twilio phone
 */
export const listUsersWithNonexistantTwilioPhone = (options) => {
    return axios.get(`/api/list_users_with_nonexistant_twilio_phone`, options);
};
export const getListUsersWithNonexistantTwilioPhoneQueryKey = () => {
    return [`/api/list_users_with_nonexistant_twilio_phone`];
};
export const getListUsersWithNonexistantTwilioPhoneQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListUsersWithNonexistantTwilioPhoneQueryKey();
    const queryFn = ({ signal }) => listUsersWithNonexistantTwilioPhone(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List users with nonexistant Twilio phone
 */
export function useListUsersWithNonexistantTwilioPhone(options) {
    const queryOptions = getListUsersWithNonexistantTwilioPhoneQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary List users
 */
export const listUsers = (params, options) => {
    return axios.get(`/api/users`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListUsersQueryKey = (params) => {
    return [`/api/users`, ...(params ? [params] : [])];
};
export const getListUsersQueryOptions = (params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListUsersQueryKey(params);
    const queryFn = ({ signal }) => listUsers(params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary List users
 */
export function useListUsers(params, options) {
    const queryOptions = getListUsersQueryOptions(params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
 * @summary Create a user
 */
export const addUser = (userCreateRequest, options) => {
    return axios.post(`/api/users`, userCreateRequest, options);
};
export const getAddUserMutationOptions = (options) => {
    const mutationKey = ['addUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addUser(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a user
*/
export const useAddUser = (options) => {
    const mutationOptions = getAddUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add a collaborator
*/
export const addCollaboratorForUser = (profileUid, userCollaboratorWriteRequest, options) => {
    return axios.post(`/api/users/${profileUid}/add_collaborator`, userCollaboratorWriteRequest, options);
};
export const getAddCollaboratorForUserMutationOptions = (options) => {
    const mutationKey = ['addCollaboratorForUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { profileUid, data } = props !== null && props !== void 0 ? props : {};
        return addCollaboratorForUser(profileUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a collaborator
*/
export const useAddCollaboratorForUser = (options) => {
    const mutationOptions = getAddCollaboratorForUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Get a presigned URL for uploading a file

_Authorization: none required_
* @summary Begin a file upload
*/
export const getUserFileUploadUrl = (profileUid, presignedURLBodyRequest, options) => {
    return axios.post(`/api/users/${profileUid}/get_file_upload_url`, presignedURLBodyRequest, options);
};
export const getGetUserFileUploadUrlMutationOptions = (options) => {
    const mutationKey = ['getUserFileUploadUrl'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { profileUid, data } = props !== null && props !== void 0 ? props : {};
        return getUserFileUploadUrl(profileUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Begin a file upload
*/
export const useGetUserFileUploadUrl = (options) => {
    const mutationOptions = getGetUserFileUploadUrlMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Purchase Twilio phone for user
*/
export const purchaseTwilioPhoneForUser = (profileUid, purchasePhoneNumberRequest, options) => {
    return axios.post(`/api/users/${profileUid}/purchase_twilio_phone`, purchasePhoneNumberRequest, options);
};
export const getPurchaseTwilioPhoneForUserMutationOptions = (options) => {
    const mutationKey = ['purchaseTwilioPhoneForUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { profileUid, data } = props !== null && props !== void 0 ? props : {};
        return purchaseTwilioPhoneForUser(profileUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Purchase Twilio phone for user
*/
export const usePurchaseTwilioPhoneForUser = (options) => {
    const mutationOptions = getPurchaseTwilioPhoneForUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Release Twilio phone for user
*/
export const releaseTwilioPhoneForUser = (profileUid, options) => {
    return axios.post(`/api/users/${profileUid}/release_twilio_phone`, undefined, options);
};
export const getReleaseTwilioPhoneForUserMutationOptions = (options) => {
    const mutationKey = ['releaseTwilioPhoneForUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { profileUid } = props !== null && props !== void 0 ? props : {};
        return releaseTwilioPhoneForUser(profileUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Release Twilio phone for user
*/
export const useReleaseTwilioPhoneForUser = (options) => {
    const mutationOptions = getReleaseTwilioPhoneForUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Remove a collaborator
*/
export const removeCollaboratorForUser = (profileUid, uid, options) => {
    return axios.delete(`/api/users/${profileUid}/remove_collaborator/${uid}`, options);
};
export const getRemoveCollaboratorForUserMutationOptions = (options) => {
    const mutationKey = ['removeCollaboratorForUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { profileUid, uid } = props !== null && props !== void 0 ? props : {};
        return removeCollaboratorForUser(profileUid, uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a collaborator
*/
export const useRemoveCollaboratorForUser = (options) => {
    const mutationOptions = getRemoveCollaboratorForUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Update a collaborator
*/
export const updateCollaboratorForUser = (profileUid, uid, userCollaboratorWriteRequest, options) => {
    return axios.post(`/api/users/${profileUid}/update_collaborator/${uid}`, userCollaboratorWriteRequest, options);
};
export const getUpdateCollaboratorForUserMutationOptions = (options) => {
    const mutationKey = ['updateCollaboratorForUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { profileUid, uid, data } = props !== null && props !== void 0 ? props : {};
        return updateCollaboratorForUser(profileUid, uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a collaborator
*/
export const useUpdateCollaboratorForUser = (options) => {
    const mutationOptions = getUpdateCollaboratorForUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get a user
*/
export const getUser = (uid, options) => {
    return axios.get(`/api/users/${uid}`, options);
};
export const getGetUserQueryKey = (uid) => {
    return [`/api/users/${uid}`];
};
export const getGetUserQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetUserQueryKey(uid);
    const queryFn = ({ signal }) => getUser(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a user
 */
export function useGetUser(uid, options) {
    const queryOptions = getGetUserQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a user
 */
export const updateUser = (uid, userUpdateRequest, options) => {
    return axios.post(`/api/users/${uid}`, userUpdateRequest, options);
};
export const getUpdateUserMutationOptions = (options) => {
    const mutationKey = ['updateUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateUser(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a user
*/
export const useUpdateUser = (options) => {
    const mutationOptions = getUpdateUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a user
*/
export const deleteUser = (uid, options) => {
    return axios.delete(`/api/users/${uid}`, options);
};
export const getDeleteUserMutationOptions = (options) => {
    const mutationKey = ['deleteUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteUser(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a user
*/
export const useDeleteUser = (options) => {
    const mutationOptions = getDeleteUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add agent status
*/
export const addAgentStatus = (userUid, agentStatusItemRequest, options) => {
    return axios.post(`/api/users/${userUid}/add_agent_status`, agentStatusItemRequest, options);
};
export const getAddAgentStatusMutationOptions = (options) => {
    const mutationKey = ['addAgentStatus'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, data } = props !== null && props !== void 0 ? props : {};
        return addAgentStatus(userUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add agent status
*/
export const useAddAgentStatus = (options) => {
    const mutationOptions = getAddAgentStatusMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add a graduation requirement
*/
export const addUserGraduationRequirement = (userUid, userGraduationRequirementWriteRequest, options) => {
    return axios.post(`/api/users/${userUid}/add_graduation_requirement`, userGraduationRequirementWriteRequest, options);
};
export const getAddUserGraduationRequirementMutationOptions = (options) => {
    const mutationKey = ['addUserGraduationRequirement'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, data } = props !== null && props !== void 0 ? props : {};
        return addUserGraduationRequirement(userUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a graduation requirement
*/
export const useAddUserGraduationRequirement = (options) => {
    const mutationOptions = getAddUserGraduationRequirementMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add a shadowing
*/
export const addUserShadowing = (userUid, userShadowingWriteRequest, options) => {
    return axios.post(`/api/users/${userUid}/add_shadowing`, userShadowingWriteRequest, options);
};
export const getAddUserShadowingMutationOptions = (options) => {
    const mutationKey = ['addUserShadowing'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, data } = props !== null && props !== void 0 ? props : {};
        return addUserShadowing(userUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a shadowing
*/
export const useAddUserShadowing = (options) => {
    const mutationOptions = getAddUserShadowingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Get VCard
*/
export const getVcardForUser = (userUid, params, options) => {
    return axios.get(`/api/users/${userUid}/get_vcard`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getGetVcardForUserQueryKey = (userUid, params) => {
    return [`/api/users/${userUid}/get_vcard`, ...(params ? [params] : [])];
};
export const getGetVcardForUserQueryOptions = (userUid, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetVcardForUserQueryKey(userUid, params);
    const queryFn = ({ signal }) => getVcardForUser(userUid, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(userUid) }, queryOptions);
};
/**
 * @summary Get VCard
 */
export function useGetVcardForUser(userUid, params, options) {
    const queryOptions = getGetVcardForUserQueryOptions(userUid, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Slack conversations that the user is involved in

_Authorization: none required_
 * @summary List Slack conversations
 */
export const listGoogleGroupsForUser = (userUid, options) => {
    return axios.get(`/api/users/${userUid}/google_groups`, options);
};
export const getListGoogleGroupsForUserQueryKey = (userUid) => {
    return [`/api/users/${userUid}/google_groups`];
};
export const getListGoogleGroupsForUserQueryOptions = (userUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListGoogleGroupsForUserQueryKey(userUid);
    const queryFn = ({ signal }) => listGoogleGroupsForUser(userUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(userUid) }, queryOptions);
};
/**
 * @summary List Slack conversations
 */
export function useListGoogleGroupsForUser(userUid, options) {
    const queryOptions = getListGoogleGroupsForUserQueryOptions(userUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Remove agent status
 */
export const removeAgentStatus = (userUid, agentStatusUid, options) => {
    return axios.delete(`/api/users/${userUid}/remove_agent_status/${agentStatusUid}`, options);
};
export const getRemoveAgentStatusMutationOptions = (options) => {
    const mutationKey = ['removeAgentStatus'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, agentStatusUid } = props !== null && props !== void 0 ? props : {};
        return removeAgentStatus(userUid, agentStatusUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove agent status
*/
export const useRemoveAgentStatus = (options) => {
    const mutationOptions = getRemoveAgentStatusMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Remove a graduation requirement
*/
export const removeUserGraduationRequirement = (userUid, graduationRequirementUid, options) => {
    return axios.delete(`/api/users/${userUid}/remove_graduation_requirement/${graduationRequirementUid}`, options);
};
export const getRemoveUserGraduationRequirementMutationOptions = (options) => {
    const mutationKey = ['removeUserGraduationRequirement'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, graduationRequirementUid } = props !== null && props !== void 0 ? props : {};
        return removeUserGraduationRequirement(userUid, graduationRequirementUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a graduation requirement
*/
export const useRemoveUserGraduationRequirement = (options) => {
    const mutationOptions = getRemoveUserGraduationRequirementMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Remove a shadowing
*/
export const removeUserShadowing = (userUid, shadowingUid, options) => {
    return axios.delete(`/api/users/${userUid}/remove_shadowing/${shadowingUid}`, options);
};
export const getRemoveUserShadowingMutationOptions = (options) => {
    const mutationKey = ['removeUserShadowing'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, shadowingUid } = props !== null && props !== void 0 ? props : {};
        return removeUserShadowing(userUid, shadowingUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a shadowing
*/
export const useRemoveUserShadowing = (options) => {
    const mutationOptions = getRemoveUserShadowingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Send VCard
*/
export const sendVcardForUser = (userUid, vCardSendRequest, options) => {
    return axios.post(`/api/users/${userUid}/send_vcard`, vCardSendRequest, options);
};
export const getSendVcardForUserMutationOptions = (options) => {
    const mutationKey = ['sendVcardForUser'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, data } = props !== null && props !== void 0 ? props : {};
        return sendVcardForUser(userUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Send VCard
*/
export const useSendVcardForUser = (options) => {
    const mutationOptions = getSendVcardForUserMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Slack conversations that the user is involved in

_Authorization: none required_
* @summary List Slack conversations
*/
export const listSlackConversationsForUser = (userUid, params, options) => {
    return axios.get(`/api/users/${userUid}/slack_conversations`, Object.assign(Object.assign({}, options), { params: Object.assign(Object.assign({}, params), options === null || options === void 0 ? void 0 : options.params) }));
};
export const getListSlackConversationsForUserQueryKey = (userUid, params) => {
    return [`/api/users/${userUid}/slack_conversations`, ...(params ? [params] : [])];
};
export const getListSlackConversationsForUserQueryOptions = (userUid, params, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListSlackConversationsForUserQueryKey(userUid, params);
    const queryFn = ({ signal }) => listSlackConversationsForUser(userUid, params, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(userUid) }, queryOptions);
};
/**
 * @summary List Slack conversations
 */
export function useListSlackConversationsForUser(userUid, params, options) {
    const queryOptions = getListSlackConversationsForUserQueryOptions(userUid, params, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a graduation requirement
 */
export const updateUserGraduationRequirement = (userUid, graduationRequirementUid, userGraduationRequirementWriteRequest, options) => {
    return axios.post(`/api/users/${userUid}/update_graduation_requirement/${graduationRequirementUid}`, userGraduationRequirementWriteRequest, options);
};
export const getUpdateUserGraduationRequirementMutationOptions = (options) => {
    const mutationKey = ['updateUserGraduationRequirement'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, graduationRequirementUid, data } = props !== null && props !== void 0 ? props : {};
        return updateUserGraduationRequirement(userUid, graduationRequirementUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a graduation requirement
*/
export const useUpdateUserGraduationRequirement = (options) => {
    const mutationOptions = getUpdateUserGraduationRequirementMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Update a shadowing
*/
export const updateUserShadowing = (userUid, shadowingUid, userShadowingWriteRequest, options) => {
    return axios.post(`/api/users/${userUid}/update_shadowing/${shadowingUid}`, userShadowingWriteRequest, options);
};
export const getUpdateUserShadowingMutationOptions = (options) => {
    const mutationKey = ['updateUserShadowing'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { userUid, shadowingUid, data } = props !== null && props !== void 0 ? props : {};
        return updateUserShadowing(userUid, shadowingUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a shadowing
*/
export const useUpdateUserShadowing = (options) => {
    const mutationOptions = getUpdateUserShadowingMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Create multiple users
*/
export const bulkCreateUsers = (userBulkCreateRequest, options) => {
    return axios.post(`/api/users/bulk_create`, userBulkCreateRequest, options);
};
export const getBulkCreateUsersMutationOptions = (options) => {
    const mutationKey = ['bulkCreateUsers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkCreateUsers(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create multiple users
*/
export const useBulkCreateUsers = (options) => {
    const mutationOptions = getBulkCreateUsersMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete multiple users
*/
export const bulkDeleteUsers = (userBulkDeleteRequest, options) => {
    return axios.post(`/api/users/bulk_delete`, userBulkDeleteRequest, options);
};
export const getBulkDeleteUsersMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteUsers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteUsers(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple users
*/
export const useBulkDeleteUsers = (options) => {
    const mutationOptions = getBulkDeleteUsersMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Update multiple users
*/
export const bulkUpdateUsers = (userBulkUpdateRequest, options) => {
    return axios.post(`/api/users/bulk_update`, userBulkUpdateRequest, options);
};
export const getBulkUpdateUsersMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateUsers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateUsers(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple users
*/
export const useBulkUpdateUsers = (options) => {
    const mutationOptions = getBulkUpdateUsersMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count users
*/
export const countUsers = (userSearchSerializerWithCertainFieldsRemoved056e3f4882d44ad6ac3bdff494dee069Request, options) => {
    return axios.post(`/api/users/count`, userSearchSerializerWithCertainFieldsRemoved056e3f4882d44ad6ac3bdff494dee069Request, options);
};
export const getCountUsersMutationOptions = (options) => {
    const mutationKey = ['countUsers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countUsers(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count users
*/
export const useCountUsers = (options) => {
    const mutationOptions = getCountUsersMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count by stage
*/
export const countUsersByStage = (userSearchSerializerWithCertainFieldsRemoved056e3f4882d44ad6ac3bdff494dee069Request, options) => {
    return axios.post(`/api/users/count_by_stage`, userSearchSerializerWithCertainFieldsRemoved056e3f4882d44ad6ac3bdff494dee069Request, options);
};
export const getCountUsersByStageMutationOptions = (options) => {
    const mutationKey = ['countUsersByStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countUsersByStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count by stage
*/
export const useCountUsersByStage = (options) => {
    const mutationOptions = getCountUsersByStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Download users
*/
export const downloadUsers = (options) => {
    return axios.get(`/api/users/download`, options);
};
export const getDownloadUsersQueryKey = () => {
    return [`/api/users/download`];
};
export const getDownloadUsersQueryOptions = (options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getDownloadUsersQueryKey();
    const queryFn = ({ signal }) => downloadUsers(Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn }, queryOptions);
};
/**
 * @summary Download users
 */
export function useDownloadUsers(options) {
    const queryOptions = getDownloadUsersQueryOptions(options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Mark a file upload as "finished"

_Authorization: none required_
 * @summary Finish a file upload
 */
export const finishUserFileUpload = (fileUid, options) => {
    return axios.post(`/api/users/finish_file_upload/${fileUid}`, undefined, options);
};
export const getFinishUserFileUploadMutationOptions = (options) => {
    const mutationKey = ['finishUserFileUpload'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { fileUid } = props !== null && props !== void 0 ? props : {};
        return finishUserFileUpload(fileUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Finish a file upload
*/
export const useFinishUserFileUpload = (options) => {
    const mutationOptions = getFinishUserFileUploadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Search users
*/
export const searchUsers = (paginatedUserSearchRequest, options) => {
    return axios.post(`/api/users/search`, paginatedUserSearchRequest, options);
};
export const getSearchUsersMutationOptions = (options) => {
    const mutationKey = ['searchUsers'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchUsers(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search users
*/
export const useSearchUsers = (options) => {
    const mutationOptions = getSearchUsersMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Search users
*/
export const searchUsersBasic = (paginatedUserSearchRequest, options) => {
    return axios.post(`/api/users/search_basic`, paginatedUserSearchRequest, options);
};
export const getSearchUsersBasicMutationOptions = (options) => {
    const mutationKey = ['searchUsersBasic'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchUsersBasic(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search users
*/
export const useSearchUsersBasic = (options) => {
    const mutationOptions = getSearchUsersBasicMutationOptions(options);
    return useMutation(mutationOptions);
};
