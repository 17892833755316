/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * _Authorization: none required_
 * @summary Create a buyer leads
 */
export const addBuyerLead = (buyerLeadCreateRequest, options) => {
    return axios.post(`/api/buyer_leads`, buyerLeadCreateRequest, options);
};
export const getAddBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addBuyerLead(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a buyer leads
*/
export const useAddBuyerLead = (options) => {
    const mutationOptions = getAddBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add an agent
*/
export const addAgentForBuyerLead = (buyerLeadUid, buyerLeadUpdateAgentRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/add_agent`, buyerLeadUpdateAgentRequest, options);
};
export const getAddAgentForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addAgentForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addAgentForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add an agent
*/
export const useAddAgentForBuyerLead = (options) => {
    const mutationOptions = getAddAgentForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a client
*/
export const addClientForBuyerLead = (buyerLeadUid, buyerLeadAddClientRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/add_client`, buyerLeadAddClientRequest, options);
};
export const getAddClientForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addClientForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addClientForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a client
*/
export const useAddClientForBuyerLead = (options) => {
    const mutationOptions = getAddClientForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a collaborator
*/
export const addCollaboratorForBuyerLead = (buyerLeadUid, buyerLeadCollaboratorWriteRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/add_collaborator`, buyerLeadCollaboratorWriteRequest, options);
};
export const getAddCollaboratorForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addCollaboratorForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addCollaboratorForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a collaborator
*/
export const useAddCollaboratorForBuyerLead = (options) => {
    const mutationOptions = getAddCollaboratorForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a property
*/
export const addPropertyForBuyerLead = (buyerLeadUid, buyerLeadPropertyRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/add_property`, buyerLeadPropertyRequest, options);
};
export const getAddPropertyForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addPropertyForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addPropertyForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a property
*/
export const useAddPropertyForBuyerLead = (options) => {
    const mutationOptions = getAddPropertyForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a vendor
*/
export const addVendorForBuyerLead = (buyerLeadUid, buyerLeadUpdateVendorRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/add_vendor`, buyerLeadUpdateVendorRequest, options);
};
export const getAddVendorForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addVendorForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return addVendorForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a vendor
*/
export const useAddVendorForBuyerLead = (options) => {
    const mutationOptions = getAddVendorForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Gmail messages related to the lead

_Authorization: only logged in, registered users can use this endpoint._
* @summary List emails for lead
*/
export const listEmailsForBuyerLead = (buyerLeadUid, options) => {
    return axios.get(`/api/buyer_leads/${buyerLeadUid}/emails`, options);
};
export const getListEmailsForBuyerLeadQueryKey = (buyerLeadUid) => {
    return [`/api/buyer_leads/${buyerLeadUid}/emails`];
};
export const getListEmailsForBuyerLeadQueryOptions = (buyerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListEmailsForBuyerLeadQueryKey(buyerLeadUid);
    const queryFn = ({ signal }) => listEmailsForBuyerLead(buyerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(buyerLeadUid) }, queryOptions);
};
/**
 * @summary List emails for lead
 */
export function useListEmailsForBuyerLead(buyerLeadUid, options) {
    const queryOptions = getListEmailsForBuyerLeadQueryOptions(buyerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Get a presigned URL for uploading a file

_Authorization: none required_
 * @summary Begin a file upload
 */
export const getBuyerLeadFileUploadUrl = (buyerLeadUid, presignedURLBodyRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/get_file_upload_url`, presignedURLBodyRequest, options);
};
export const getGetBuyerLeadFileUploadUrlMutationOptions = (options) => {
    const mutationKey = ['getBuyerLeadFileUploadUrl'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return getBuyerLeadFileUploadUrl(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Begin a file upload
*/
export const useGetBuyerLeadFileUploadUrl = (options) => {
    const mutationOptions = getGetBuyerLeadFileUploadUrlMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Removes a client from the lead. Does not delete the client.

_Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a client
*/
export const removeClientForBuyerLead = (buyerLeadUid, buyerLeadRemoveClientRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/remove_client`, buyerLeadRemoveClientRequest, options);
};
export const getRemoveClientForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['removeClientForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return removeClientForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a client
*/
export const useRemoveClientForBuyerLead = (options) => {
    const mutationOptions = getRemoveClientForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a collaborator
*/
export const removeCollaboratorForBuyerLead = (buyerLeadUid, uid, options) => {
    return axios.delete(`/api/buyer_leads/${buyerLeadUid}/remove_collaborator/${uid}`, options);
};
export const getRemoveCollaboratorForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['removeCollaboratorForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, uid } = props !== null && props !== void 0 ? props : {};
        return removeCollaboratorForBuyerLead(buyerLeadUid, uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a collaborator
*/
export const useRemoveCollaboratorForBuyerLead = (options) => {
    const mutationOptions = getRemoveCollaboratorForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Reorder clients
*/
export const reorderClientsForBuyerLead = (buyerLeadUid, buyerLeadReorderClientRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/reorder_clients`, buyerLeadReorderClientRequest, options);
};
export const getReorderClientsForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['reorderClientsForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return reorderClientsForBuyerLead(buyerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Reorder clients
*/
export const useReorderClientsForBuyerLead = (options) => {
    const mutationOptions = getReorderClientsForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Streak comments related to the lead

_Authorization: only logged in, registered users can use this endpoint._
* @summary List Streak comments for lead
*/
export const listStreakCommentsForBuyerLead = (buyerLeadUid, options) => {
    return axios.get(`/api/buyer_leads/${buyerLeadUid}/streak_comments`, options);
};
export const getListStreakCommentsForBuyerLeadQueryKey = (buyerLeadUid) => {
    return [`/api/buyer_leads/${buyerLeadUid}/streak_comments`];
};
export const getListStreakCommentsForBuyerLeadQueryOptions = (buyerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakCommentsForBuyerLeadQueryKey(buyerLeadUid);
    const queryFn = ({ signal }) => listStreakCommentsForBuyerLead(buyerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(buyerLeadUid) }, queryOptions);
};
/**
 * @summary List Streak comments for lead
 */
export function useListStreakCommentsForBuyerLead(buyerLeadUid, options) {
    const queryOptions = getListStreakCommentsForBuyerLeadQueryOptions(buyerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Streak files related to the lead

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List Streak files for lead
 */
export const listStreakFilesForBuyerLead = (buyerLeadUid, options) => {
    return axios.get(`/api/buyer_leads/${buyerLeadUid}/streak_files`, options);
};
export const getListStreakFilesForBuyerLeadQueryKey = (buyerLeadUid) => {
    return [`/api/buyer_leads/${buyerLeadUid}/streak_files`];
};
export const getListStreakFilesForBuyerLeadQueryOptions = (buyerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListStreakFilesForBuyerLeadQueryKey(buyerLeadUid);
    const queryFn = ({ signal }) => listStreakFilesForBuyerLead(buyerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(buyerLeadUid) }, queryOptions);
};
/**
 * @summary List Streak files for lead
 */
export function useListStreakFilesForBuyerLead(buyerLeadUid, options) {
    const queryOptions = getListStreakFilesForBuyerLeadQueryOptions(buyerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: only logged in, registered users can use this endpoint._
 * @summary Update a collaborator
 */
export const updateCollaboratorForBuyerLead = (buyerLeadUid, uid, buyerLeadCollaboratorWriteRequest, options) => {
    return axios.post(`/api/buyer_leads/${buyerLeadUid}/update_collaborator/${uid}`, buyerLeadCollaboratorWriteRequest, options);
};
export const getUpdateCollaboratorForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['updateCollaboratorForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { buyerLeadUid, uid, data } = props !== null && props !== void 0 ? props : {};
        return updateCollaboratorForBuyerLead(buyerLeadUid, uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a collaborator
*/
export const useUpdateCollaboratorForBuyerLead = (options) => {
    const mutationOptions = getUpdateCollaboratorForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Get a buyer lead
*/
export const getBuyerLead = (uid, options) => {
    return axios.get(`/api/buyer_leads/${uid}`, options);
};
export const getGetBuyerLeadQueryKey = (uid) => {
    return [`/api/buyer_leads/${uid}`];
};
export const getGetBuyerLeadQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetBuyerLeadQueryKey(uid);
    const queryFn = ({ signal }) => getBuyerLead(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a buyer lead
 */
export function useGetBuyerLead(uid, options) {
    const queryOptions = getGetBuyerLeadQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a buyer lead
 */
export const updateBuyerLead = (uid, buyerLeadUpdateRequest, options) => {
    return axios.post(`/api/buyer_leads/${uid}`, buyerLeadUpdateRequest, options);
};
export const getUpdateBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['updateBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateBuyerLead(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a buyer lead
*/
export const useUpdateBuyerLead = (options) => {
    const mutationOptions = getUpdateBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Delete a buyer lead
*/
export const deleteBuyerLead = (uid, options) => {
    return axios.delete(`/api/buyer_leads/${uid}`, options);
};
export const getDeleteBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['deleteBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a buyer lead
*/
export const useDeleteBuyerLead = (options) => {
    const mutationOptions = getDeleteBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Duplicate a buyer lead
*/
export const duplicateBuyerLead = (uid, options) => {
    return axios.post(`/api/buyer_leads/${uid}/duplicate`, undefined, options);
};
export const getDuplicateBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['duplicateBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a buyer lead
*/
export const useDuplicateBuyerLead = (options) => {
    const mutationOptions = getDuplicateBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Duplicate a buyer lead as a seller lead
*/
export const duplicateBuyerLeadAsSellerLead = (uid, options) => {
    return axios.post(`/api/buyer_leads/${uid}/duplicate_as_seller_lead`, undefined, options);
};
export const getDuplicateBuyerLeadAsSellerLeadMutationOptions = (options) => {
    const mutationKey = ['duplicateBuyerLeadAsSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return duplicateBuyerLeadAsSellerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Duplicate a buyer lead as a seller lead
*/
export const useDuplicateBuyerLeadAsSellerLead = (options) => {
    const mutationOptions = getDuplicateBuyerLeadAsSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Create multiple buyer leads
*/
export const bulkCreateBuyerLeads = (buyerLeadBulkCreateRequest, options) => {
    return axios.post(`/api/buyer_leads/bulk_create`, buyerLeadBulkCreateRequest, options);
};
export const getBulkCreateBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['bulkCreateBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkCreateBuyerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create multiple buyer leads
*/
export const useBulkCreateBuyerLeads = (options) => {
    const mutationOptions = getBulkCreateBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete multiple buyer leads
*/
export const bulkDeleteBuyerLeads = (buyerLeadBulkDeleteRequest, options) => {
    return axios.post(`/api/buyer_leads/bulk_delete`, buyerLeadBulkDeleteRequest, options);
};
export const getBulkDeleteBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteBuyerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple buyer leads
*/
export const useBulkDeleteBuyerLeads = (options) => {
    const mutationOptions = getBulkDeleteBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Update multiple buyer leads
*/
export const bulkUpdateBuyerLeads = (buyerLeadBulkUpdateRequest, options) => {
    return axios.post(`/api/buyer_leads/bulk_update`, buyerLeadBulkUpdateRequest, options);
};
export const getBulkUpdateBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateBuyerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple buyer leads
*/
export const useBulkUpdateBuyerLeads = (options) => {
    const mutationOptions = getBulkUpdateBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count buyer leads
*/
export const countBuyerLeads = (buyerLeadQuerySerializerWithCertainFieldsRemoved6db4311513144395b047dd708d39400cRequest, options) => {
    return axios.post(`/api/buyer_leads/count`, buyerLeadQuerySerializerWithCertainFieldsRemoved6db4311513144395b047dd708d39400cRequest, options);
};
export const getCountBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['countBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countBuyerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count buyer leads
*/
export const useCountBuyerLeads = (options) => {
    const mutationOptions = getCountBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Count by stage
*/
export const countBuyerLeadsByStage = (buyerLeadQuerySerializerWithCertainFieldsRemoved6db4311513144395b047dd708d39400cRequest, options) => {
    return axios.post(`/api/buyer_leads/count_by_stage`, buyerLeadQuerySerializerWithCertainFieldsRemoved6db4311513144395b047dd708d39400cRequest, options);
};
export const getCountBuyerLeadsByStageMutationOptions = (options) => {
    const mutationKey = ['countBuyerLeadsByStage'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countBuyerLeadsByStage(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count by stage
*/
export const useCountBuyerLeadsByStage = (options) => {
    const mutationOptions = getCountBuyerLeadsByStageMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Download buyer leads
*/
export const downloadBuyerLeads = (options) => {
    return axios.post(`/api/buyer_leads/download`, undefined, options);
};
export const getDownloadBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['downloadBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return downloadBuyerLeads(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Download buyer leads
*/
export const useDownloadBuyerLeads = (options) => {
    const mutationOptions = getDownloadBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Mark a file upload as "finished"

_Authorization: none required_
* @summary Finish a file upload
*/
export const finishBuyerLeadFileUpload = (fileUid, options) => {
    return axios.post(`/api/buyer_leads/finish_file_upload/${fileUid}`, undefined, options);
};
export const getFinishBuyerLeadFileUploadMutationOptions = (options) => {
    const mutationKey = ['finishBuyerLeadFileUpload'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { fileUid } = props !== null && props !== void 0 ? props : {};
        return finishBuyerLeadFileUpload(fileUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Finish a file upload
*/
export const useFinishBuyerLeadFileUpload = (options) => {
    const mutationOptions = getFinishBuyerLeadFileUploadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Import buyer leads
*/
export const importBuyerLeads = (importBuyerLeadRequest, options) => {
    return axios.post(`/api/buyer_leads/import`, importBuyerLeadRequest, options);
};
export const getImportBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['importBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return importBuyerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Import buyer leads
*/
export const useImportBuyerLeads = (options) => {
    const mutationOptions = getImportBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Add a showing
*/
export const addShowingForBuyerLead = (propertyUid, buyerLeadUpdateShowingRequest, options) => {
    return axios.post(`/api/buyer_leads/properties/${propertyUid}/add_showing`, buyerLeadUpdateShowingRequest, options);
};
export const getAddShowingForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['addShowingForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { propertyUid, data } = props !== null && props !== void 0 ? props : {};
        return addShowingForBuyerLead(propertyUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add a showing
*/
export const useAddShowingForBuyerLead = (options) => {
    const mutationOptions = getAddShowingForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
*
Creates a new PSP for a property and links it to the property.
Attempts to fill in as many fields as possible by pulling data
from the Buyer Lead Property, various MLS APIs, and scraping online
reports (which were previously pulled via Chrome Extension).
Uses the Smart Search feature to populate comps. Attempts to select
the best comps using our internal AI Comps Selector API (does not
exist yet).


_Authorization: only logged in, registered users can use this endpoint._
* @summary Create a PSP
*/
export const createPspForProperty = (propertyUid, options) => {
    return axios.post(`/api/buyer_leads/properties/${propertyUid}/create_psp_for_property`, undefined, options);
};
export const getCreatePspForPropertyMutationOptions = (options) => {
    const mutationKey = ['createPspForProperty'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { propertyUid } = props !== null && props !== void 0 ? props : {};
        return createPspForProperty(propertyUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a PSP
*/
export const useCreatePspForProperty = (options) => {
    const mutationOptions = getCreatePspForPropertyMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Link an existing PSP to a property

_Authorization: only logged in, registered users can use this endpoint._
* @summary Link a PSP
*/
export const linkPspToBuyerLeadProperty = (propertyUid, pspUid, options) => {
    return axios.post(`/api/buyer_leads/properties/${propertyUid}/link_psp/${pspUid}`, undefined, options);
};
export const getLinkPspToBuyerLeadPropertyMutationOptions = (options) => {
    const mutationKey = ['linkPspToBuyerLeadProperty'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { propertyUid, pspUid } = props !== null && props !== void 0 ? props : {};
        return linkPspToBuyerLeadProperty(propertyUid, pspUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Link a PSP
*/
export const useLinkPspToBuyerLeadProperty = (options) => {
    const mutationOptions = getLinkPspToBuyerLeadPropertyMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Link an existing QFO to a property

_Authorization: only logged in, registered users can use this endpoint._
* @summary Link a QFO
*/
export const linkQfoToBuyerLeadProperty = (propertyUid, qfoUid, options) => {
    return axios.post(`/api/buyer_leads/properties/${propertyUid}/link_qfo/${qfoUid}`, undefined, options);
};
export const getLinkQfoToBuyerLeadPropertyMutationOptions = (options) => {
    const mutationKey = ['linkQfoToBuyerLeadProperty'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { propertyUid, qfoUid } = props !== null && props !== void 0 ? props : {};
        return linkQfoToBuyerLeadProperty(propertyUid, qfoUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Link a QFO
*/
export const useLinkQfoToBuyerLeadProperty = (options) => {
    const mutationOptions = getLinkQfoToBuyerLeadPropertyMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Unlink a PSP from a property

_Authorization: only logged in, registered users can use this endpoint._
* @summary Unlink a PSP
*/
export const unlinkPspFromBuyerLeadProperty = (propertyUid, pspUid, options) => {
    return axios.delete(`/api/buyer_leads/properties/${propertyUid}/unlink_psp/${pspUid}`, options);
};
export const getUnlinkPspFromBuyerLeadPropertyMutationOptions = (options) => {
    const mutationKey = ['unlinkPspFromBuyerLeadProperty'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { propertyUid, pspUid } = props !== null && props !== void 0 ? props : {};
        return unlinkPspFromBuyerLeadProperty(propertyUid, pspUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Unlink a PSP
*/
export const useUnlinkPspFromBuyerLeadProperty = (options) => {
    const mutationOptions = getUnlinkPspFromBuyerLeadPropertyMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Unlink a QFO from a property

_Authorization: only logged in, registered users can use this endpoint._
* @summary Unlink a QFO
*/
export const unlinkQfoFromBuyerLeadProperty = (propertyUid, qfoUid, options) => {
    return axios.delete(`/api/buyer_leads/properties/${propertyUid}/unlink_qfo/${qfoUid}`, options);
};
export const getUnlinkQfoFromBuyerLeadPropertyMutationOptions = (options) => {
    const mutationKey = ['unlinkQfoFromBuyerLeadProperty'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { propertyUid, qfoUid } = props !== null && props !== void 0 ? props : {};
        return unlinkQfoFromBuyerLeadProperty(propertyUid, qfoUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Unlink a QFO
*/
export const useUnlinkQfoFromBuyerLeadProperty = (options) => {
    const mutationOptions = getUnlinkQfoFromBuyerLeadPropertyMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a showing
*/
export const removeShowingForBuyerLead = (uid, options) => {
    return axios.delete(`/api/buyer_leads/properties/remove_showing/${uid}`, options);
};
export const getRemoveShowingForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['removeShowingForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removeShowingForBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a showing
*/
export const useRemoveShowingForBuyerLead = (options) => {
    const mutationOptions = getRemoveShowingForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update a showing
*/
export const updateShowingForBuyerLead = (uid, buyerLeadUpdateShowingRequest, options) => {
    return axios.post(`/api/buyer_leads/properties/update_showing/${uid}`, buyerLeadUpdateShowingRequest, options);
};
export const getUpdateShowingForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['updateShowingForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateShowingForBuyerLead(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a showing
*/
export const useUpdateShowingForBuyerLead = (options) => {
    const mutationOptions = getUpdateShowingForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Removes an agent from the lead. Does not delete the agent profile.

_Authorization: only logged in, registered users can use this endpoint._
* @summary Remove an agent
*/
export const removeAgentForBuyerLead = (uid, options) => {
    return axios.delete(`/api/buyer_leads/remove_agent/${uid}`, options);
};
export const getRemoveAgentForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['removeAgentForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removeAgentForBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove an agent
*/
export const useRemoveAgentForBuyerLead = (options) => {
    const mutationOptions = getRemoveAgentForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a property
*/
export const removePropertyForBuyerLead = (uid, options) => {
    return axios.delete(`/api/buyer_leads/remove_property/${uid}`, options);
};
export const getRemovePropertyForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['removePropertyForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removePropertyForBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a property
*/
export const useRemovePropertyForBuyerLead = (options) => {
    const mutationOptions = getRemovePropertyForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Remove a vendor
*/
export const removeVendorForBuyerLead = (uid, options) => {
    return axios.delete(`/api/buyer_leads/remove_vendor/${uid}`, options);
};
export const getRemoveVendorForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['removeVendorForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return removeVendorForBuyerLead(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove a vendor
*/
export const useRemoveVendorForBuyerLead = (options) => {
    const mutationOptions = getRemoveVendorForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Search buyer leads
*/
export const searchBuyerLeads = (paginatedBuyerLeadQueryRequest, options) => {
    return axios.post(`/api/buyer_leads/search`, paginatedBuyerLeadQueryRequest, options);
};
export const getSearchBuyerLeadsMutationOptions = (options) => {
    const mutationKey = ['searchBuyerLeads'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchBuyerLeads(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search buyer leads
*/
export const useSearchBuyerLeads = (options) => {
    const mutationOptions = getSearchBuyerLeadsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update an agent
*/
export const updateAgentForBuyerLead = (uid, buyerLeadUpdateAgentRequest, options) => {
    return axios.post(`/api/buyer_leads/update_agent/${uid}`, buyerLeadUpdateAgentRequest, options);
};
export const getUpdateAgentForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['updateAgentForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateAgentForBuyerLead(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update an agent
*/
export const useUpdateAgentForBuyerLead = (options) => {
    const mutationOptions = getUpdateAgentForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update a property
*/
export const updatePropertyForBuyerLead = (uid, buyerLeadPropertyRequest, options) => {
    return axios.post(`/api/buyer_leads/update_property/${uid}`, buyerLeadPropertyRequest, options);
};
export const getUpdatePropertyForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['updatePropertyForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updatePropertyForBuyerLead(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a property
*/
export const useUpdatePropertyForBuyerLead = (options) => {
    const mutationOptions = getUpdatePropertyForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users can use this endpoint._
* @summary Update a vendor
*/
export const updateVendorForBuyerLead = (uid, buyerLeadUpdateVendorRequest, options) => {
    return axios.post(`/api/buyer_leads/update_vendor/${uid}`, buyerLeadUpdateVendorRequest, options);
};
export const getUpdateVendorForBuyerLeadMutationOptions = (options) => {
    const mutationKey = ['updateVendorForBuyerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateVendorForBuyerLead(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a vendor
*/
export const useUpdateVendorForBuyerLead = (options) => {
    const mutationOptions = getUpdateVendorForBuyerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
*
Creates a new PSP for a seller lead and links it to the lead.
Attempts to fill in as many fields as possible by pulling data
from the lead, various MLS APIs, and scraping online
reports (which were previously pulled via Chrome Extension).
Uses the Smart Search feature to populate comps. Attempts to select
the best comps using our internal AI Comps Selector API (does not
exist yet).


_Authorization: only logged in, registered users can use this endpoint._
* @summary Create a PSP
*/
export const createPspWithMarketAnalysisForSellerLead = (sellerLeadUid, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/create_psp_with_market_analysis`, undefined, options);
};
export const getCreatePspWithMarketAnalysisForSellerLeadMutationOptions = (options) => {
    const mutationKey = ['createPspWithMarketAnalysisForSellerLead'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid } = props !== null && props !== void 0 ? props : {};
        return createPspWithMarketAnalysisForSellerLead(sellerLeadUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a PSP
*/
export const useCreatePspWithMarketAnalysisForSellerLead = (options) => {
    const mutationOptions = getCreatePspWithMarketAnalysisForSellerLeadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Get a presigned URL for uploading a file

_Authorization: none required_
* @summary Begin a file upload
*/
export const getSellerLeadFileUploadUrl = (sellerLeadUid, presignedURLBodyRequest, options) => {
    return axios.post(`/api/seller_leads/${sellerLeadUid}/get_file_upload_url`, presignedURLBodyRequest, options);
};
export const getGetSellerLeadFileUploadUrlMutationOptions = (options) => {
    const mutationKey = ['getSellerLeadFileUploadUrl'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { sellerLeadUid, data } = props !== null && props !== void 0 ? props : {};
        return getSellerLeadFileUploadUrl(sellerLeadUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Begin a file upload
*/
export const useGetSellerLeadFileUploadUrl = (options) => {
    const mutationOptions = getGetSellerLeadFileUploadUrlMutationOptions(options);
    return useMutation(mutationOptions);
};
