/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * DASH Realty API
 * Documentation for the DASH Realty API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
/**
 * Gets all Twilio calls for the lead

_Authorization: only logged in, registered users can use this endpoint._
 * @summary List calls for lead
 */
export const listCallsForBuyerLead = (buyerLeadUid, options) => {
    return axios.get(`/api/buyer_leads/${buyerLeadUid}/calls`, options);
};
export const getListCallsForBuyerLeadQueryKey = (buyerLeadUid) => {
    return [`/api/buyer_leads/${buyerLeadUid}/calls`];
};
export const getListCallsForBuyerLeadQueryOptions = (buyerLeadUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCallsForBuyerLeadQueryKey(buyerLeadUid);
    const queryFn = ({ signal }) => listCallsForBuyerLead(buyerLeadUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(buyerLeadUid) }, queryOptions);
};
/**
 * @summary List calls for lead
 */
export function useListCallsForBuyerLead(buyerLeadUid, options) {
    const queryOptions = getListCallsForBuyerLeadQueryOptions(buyerLeadUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Create a contact
 */
export const createContact = (contactCreateRequest, options) => {
    return axios.post(`/api/contacts`, contactCreateRequest, options);
};
export const getCreateContactMutationOptions = (options) => {
    const mutationKey = ['createContact'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return createContact(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create a contact
*/
export const useCreateContact = (options) => {
    const mutationOptions = getCreateContactMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Twilio calls involving the contact

_Authorization: none required_
* @summary List calls for contact
*/
export const listCallsForContact = (contactUid, options) => {
    return axios.get(`/api/contacts/${contactUid}/calls`, options);
};
export const getListCallsForContactQueryKey = (contactUid) => {
    return [`/api/contacts/${contactUid}/calls`];
};
export const getListCallsForContactQueryOptions = (contactUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCallsForContactQueryKey(contactUid);
    const queryFn = ({ signal }) => listCallsForContact(contactUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(contactUid) }, queryOptions);
};
/**
 * @summary List calls for contact
 */
export function useListCallsForContact(contactUid, options) {
    const queryOptions = getListCallsForContactQueryOptions(contactUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Gets all Gmail messages sent to or received from the contact

_Authorization: none required_
 * @summary List emails for contact
 */
export const listEmailsForContact = (contactUid, options) => {
    return axios.get(`/api/contacts/${contactUid}/emails`, options);
};
export const getListEmailsForContactQueryKey = (contactUid) => {
    return [`/api/contacts/${contactUid}/emails`];
};
export const getListEmailsForContactQueryOptions = (contactUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListEmailsForContactQueryKey(contactUid);
    const queryFn = ({ signal }) => listEmailsForContact(contactUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(contactUid) }, queryOptions);
};
/**
 * @summary List emails for contact
 */
export function useListEmailsForContact(contactUid, options) {
    const queryOptions = getListEmailsForContactQueryOptions(contactUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * Get a presigned URL for uploading a file

_Authorization: none required_
 * @summary Begin a file upload
 */
export const getContactFileUploadUrl = (contactUid, presignedURLBodyRequest, options) => {
    return axios.post(`/api/contacts/${contactUid}/get_file_upload_url`, presignedURLBodyRequest, options);
};
export const getGetContactFileUploadUrlMutationOptions = (options) => {
    const mutationKey = ['getContactFileUploadUrl'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { contactUid, data } = props !== null && props !== void 0 ? props : {};
        return getContactFileUploadUrl(contactUid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Begin a file upload
*/
export const useGetContactFileUploadUrl = (options) => {
    const mutationOptions = getGetContactFileUploadUrlMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Twilio messages sent to or received from the contact

_Authorization: none required_
* @summary List messages for contact
*/
export const listMessagesForContact = (contactUid, options) => {
    return axios.get(`/api/contacts/${contactUid}/messages`, options);
};
export const getListMessagesForContactQueryKey = (contactUid) => {
    return [`/api/contacts/${contactUid}/messages`];
};
export const getListMessagesForContactQueryOptions = (contactUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListMessagesForContactQueryKey(contactUid);
    const queryFn = ({ signal }) => listMessagesForContact(contactUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(contactUid) }, queryOptions);
};
/**
 * @summary List messages for contact
 */
export function useListMessagesForContact(contactUid, options) {
    const queryOptions = getListMessagesForContactQueryOptions(contactUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Get a contact
 */
export const getContact = (uid, options) => {
    return axios.get(`/api/contacts/${uid}`, options);
};
export const getGetContactQueryKey = (uid) => {
    return [`/api/contacts/${uid}`];
};
export const getGetContactQueryOptions = (uid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getGetContactQueryKey(uid);
    const queryFn = ({ signal }) => getContact(uid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(uid) }, queryOptions);
};
/**
 * @summary Get a contact
 */
export function useGetContact(uid, options) {
    const queryOptions = getGetContactQueryOptions(uid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
/**
 * _Authorization: none required_
 * @summary Update a contact
 */
export const updateContact = (uid, contactUpdateRequest, options) => {
    return axios.post(`/api/contacts/${uid}`, contactUpdateRequest, options);
};
export const getUpdateContactMutationOptions = (options) => {
    const mutationKey = ['updateContact'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid, data } = props !== null && props !== void 0 ? props : {};
        return updateContact(uid, data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update a contact
*/
export const useUpdateContact = (options) => {
    const mutationOptions = getUpdateContactMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete a contact
*/
export const deleteContact = (uid, options) => {
    return axios.delete(`/api/contacts/${uid}`, options);
};
export const getDeleteContactMutationOptions = (options) => {
    const mutationKey = ['deleteContact'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { uid } = props !== null && props !== void 0 ? props : {};
        return deleteContact(uid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete a contact
*/
export const useDeleteContact = (options) => {
    const mutationOptions = getDeleteContactMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Add related contact
*/
export const addRelatedContact = (addRelatedContactRequest, options) => {
    return axios.post(`/api/contacts/add_relationship`, addRelatedContactRequest, options);
};
export const getAddRelatedContactMutationOptions = (options) => {
    const mutationKey = ['addRelatedContact'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return addRelatedContact(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Add related contact
*/
export const useAddRelatedContact = (options) => {
    const mutationOptions = getAddRelatedContactMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Create multiple contacts
*/
export const bulkCreateContacts = (contactBulkCreateRequest, options) => {
    return axios.post(`/api/contacts/bulk_create`, contactBulkCreateRequest, options);
};
export const getBulkCreateContactsMutationOptions = (options) => {
    const mutationKey = ['bulkCreateContacts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkCreateContacts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Create multiple contacts
*/
export const useBulkCreateContacts = (options) => {
    const mutationOptions = getBulkCreateContactsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Delete multiple contacts
*/
export const bulkDeleteContacts = (contactBulkDeleteRequest, options) => {
    return axios.post(`/api/contacts/bulk_delete`, contactBulkDeleteRequest, options);
};
export const getBulkDeleteContactsMutationOptions = (options) => {
    const mutationKey = ['bulkDeleteContacts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkDeleteContacts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Delete multiple contacts
*/
export const useBulkDeleteContacts = (options) => {
    const mutationOptions = getBulkDeleteContactsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Update multiple contacts
*/
export const bulkUpdateContacts = (contactBulkUpdateRequest, options) => {
    return axios.post(`/api/contacts/bulk_update`, contactBulkUpdateRequest, options);
};
export const getBulkUpdateContactsMutationOptions = (options) => {
    const mutationKey = ['bulkUpdateContacts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return bulkUpdateContacts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Update multiple contacts
*/
export const useBulkUpdateContacts = (options) => {
    const mutationOptions = getBulkUpdateContactsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count contacts
*/
export const countContacts = (contactQuerySerializerWithCertainFieldsRemovedBc8209e067744a2b850c596b46d07d22Request, options) => {
    return axios.post(`/api/contacts/count`, contactQuerySerializerWithCertainFieldsRemovedBc8209e067744a2b850c596b46d07d22Request, options);
};
export const getCountContactsMutationOptions = (options) => {
    const mutationKey = ['countContacts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countContacts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count contacts
*/
export const useCountContacts = (options) => {
    const mutationOptions = getCountContactsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Count contacts by vendor type
*/
export const countContactsByVendorType = (contactQuerySerializerWithCertainFieldsRemovedBc8209e067744a2b850c596b46d07d22Request, options) => {
    return axios.post(`/api/contacts/count_by_vendor_type`, contactQuerySerializerWithCertainFieldsRemovedBc8209e067744a2b850c596b46d07d22Request, options);
};
export const getCountContactsByVendorTypeMutationOptions = (options) => {
    const mutationKey = ['countContactsByVendorType'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return countContactsByVendorType(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Count contacts by vendor type
*/
export const useCountContactsByVendorType = (options) => {
    const mutationOptions = getCountContactsByVendorTypeMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin._
* @summary Download contacts
*/
export const downloadContacts = (options) => {
    return axios.post(`/api/contacts/download`, undefined, options);
};
export const getDownloadContactsMutationOptions = (options) => {
    const mutationKey = ['downloadContacts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = () => {
        return downloadContacts(axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Download contacts
*/
export const useDownloadContacts = (options) => {
    const mutationOptions = getDownloadContactsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Mark a file upload as "finished"

_Authorization: none required_
* @summary Finish a file upload
*/
export const finishContactFileUpload = (fileUid, options) => {
    return axios.post(`/api/contacts/finish_file_upload/${fileUid}`, undefined, options);
};
export const getFinishContactFileUploadMutationOptions = (options) => {
    const mutationKey = ['finishContactFileUpload'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { fileUid } = props !== null && props !== void 0 ? props : {};
        return finishContactFileUpload(fileUid, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Finish a file upload
*/
export const useFinishContactFileUpload = (options) => {
    const mutationOptions = getFinishContactFileUploadMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Remove related contact
*/
export const removeRelatedContact = (removeRelatedContactRequest, options) => {
    return axios.post(`/api/contacts/remove_relationship`, removeRelatedContactRequest, options);
};
export const getRemoveRelatedContactMutationOptions = (options) => {
    const mutationKey = ['removeRelatedContact'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return removeRelatedContact(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Remove related contact
*/
export const useRemoveRelatedContact = (options) => {
    const mutationOptions = getRemoveRelatedContactMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* _Authorization: none required_
* @summary Search contacts
*/
export const searchContacts = (paginatedContactQueryRequest, options) => {
    return axios.post(`/api/contacts/search`, paginatedContactQueryRequest, options);
};
export const getSearchContactsMutationOptions = (options) => {
    const mutationKey = ['searchContacts'];
    const { mutation: mutationOptions, axios: axiosOptions } = options ?
        options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
            options
            : Object.assign(Object.assign({}, options), { mutation: Object.assign(Object.assign({}, options.mutation), { mutationKey }) })
        : { mutation: { mutationKey, }, axios: undefined };
    const mutationFn = (props) => {
        const { data } = props !== null && props !== void 0 ? props : {};
        return searchContacts(data, axiosOptions);
    };
    return Object.assign({ mutationFn }, mutationOptions);
};
/**
* @summary Search contacts
*/
export const useSearchContacts = (options) => {
    const mutationOptions = getSearchContactsMutationOptions(options);
    return useMutation(mutationOptions);
};
/**
* Gets all Twilio calls for the lead

_Authorization: only logged in, registered users with one of the following roles can use this endpoint: Superadmin, Admin, Managing Partner._
* @summary List calls for a recruit
*/
export const listCallsForRecruit = (recruitUid, options) => {
    return axios.get(`/api/recruits/${recruitUid}/calls`, options);
};
export const getListCallsForRecruitQueryKey = (recruitUid) => {
    return [`/api/recruits/${recruitUid}/calls`];
};
export const getListCallsForRecruitQueryOptions = (recruitUid, options) => {
    var _a;
    const { query: queryOptions, axios: axiosOptions } = options !== null && options !== void 0 ? options : {};
    const queryKey = (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.queryKey) !== null && _a !== void 0 ? _a : getListCallsForRecruitQueryKey(recruitUid);
    const queryFn = ({ signal }) => listCallsForRecruit(recruitUid, Object.assign({ signal }, axiosOptions));
    return Object.assign({ queryKey, queryFn, enabled: !!(recruitUid) }, queryOptions);
};
/**
 * @summary List calls for a recruit
 */
export function useListCallsForRecruit(recruitUid, options) {
    const queryOptions = getListCallsForRecruitQueryOptions(recruitUid, options);
    const query = useQuery(queryOptions);
    query.queryKey = queryOptions.queryKey;
    return query;
}
